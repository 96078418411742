import { useEffect, useState } from "react";

export function useScreenSize() {
  const [size, setSize] = useState<[number, number]>([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const onResize = () => {
      const [oldWidth, oldHeight] = size;
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width !== oldWidth || height !== oldHeight) setSize([width, height]);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [size, setSize]);
  return size;
}