import './App.css';
import { useScreenSize } from './useScreenSize';

function App() {
  const [vWidth, _vHeight] = useScreenSize();
  const compWidth = Math.min(1500, vWidth * 0.8);
  const compHeight = compWidth * 4 / 3;
  const compLeft = (vWidth - compWidth) / 2;
  const compTop = 0;
  
  const bgStyle = {
    left: compLeft,
    top: compTop,
    width: compWidth,
    height: compHeight
  };

  const headerStyle = {
    left: compLeft,
    top: compTop + compHeight * 0.25,
    width: compWidth,
  };

  const aboutStyle = {
    top: compTop + compHeight
  };

  return (
    <div className="app">
      <div className="backgrounds" style={bgStyle}>
        <div className="bg-bg"/>
        <div className="bg-aeon"/>
        <div className="bg-gaian"/>
        <div className="bg-sai"/>
        <div className="bg-player"/>
        <div className="bg-adana"/>
        <div className="bg-fenronir"/>
      </div>
      <header className="app-header" style={headerStyle}>
        <div className="presents">
          <div className="gn-logo"/>
          <h2>Gnarled Helix LLC presents</h2>
        </div>
        <h1>Open Sourcerer</h1>
      </header>
      <div className="about" style={aboutStyle}>
        <p>
          <span className="game-title">Open Sourcerer</span> is a platformer where players harness JavaScript to solve puzzles and defeat enemies.
        </p>
        <p>
          More to come soon.
        </p>
      </div>
    </div>
  );
}

export default App;
